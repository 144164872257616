<section class="h-full">
    
    <div class="h-1/5 max-h-[250px] flex items-center justify-center lg:items-start lg:pt-8">
        <h3 class="text-xl font-semibold lg:text-3xl xl:text-4xl text-darkgray">
            {{templateVars.header}}
        </h3>
    </div>
    
    <div class="h-4/5 bg-white">
        <div class="container h-full overflow-y-auto mx-auto px-3 sm:px-10 lg:px-32 xl:px-52">
            
            <!-- Introduction -->
            <p class="text-center text-sm px-10 py-5 lg:pt-14 text-lightgray">
                {{templateVars.subheader}}
            </p>

            <!-- CURRENT USER CARD -->
            <app-reserved-friend-card
             [name]="formatName(user.tdc_info)" 
             [supporterId]="user.tdc_info.id" 
             [isSelected]="selectedUsers().includes(user.tdc_info.id)"
             [force]="true"
             (selectUser)="updateSelectedUsers($event)"
             />

            <!-- FRIENDS CARDS -->
            <ng-container *ngIf="associations()?.length">

                <!-- SEPARADOR -->
                <div class="hidden lg:block py-8 ml-2">
                    <p class="block text-lg font-semibold text-darkgray">
                        {{templateVars.separator}}
                    </p>
                </div>

                <!-- CARDS -->
                <div class="mt-5 mb-20 lg:mt-0 lg:mb-32 flex flex-col gap-y-5 lg:gap-y-3">
                    <app-reserved-friend-card 
                        *ngFor="let friend of associations()" 
                        [name]="formatName(friend.tdc_info)" 
                        [supporterId]="friend.associate_id"
                        [isSelected]="selectedUsers().includes(friend.associate_id)"
                        [extraData]="friend.tdc_info" 
                        (selectUser)="updateSelectedUsers($event)"
                    />
                </div>

            </ng-container>

            <!-- LOADER -->
            <ng-container>
                <div class="flex justify-center mt-10">
                    <app-loader/>
                </div>
            </ng-container>

        
        </div>
    </div>

</section>
<app-footer 
    buttonText="Continue" 
    [textDetail]="friendsSelected().length.toString() + (friendsSelected().length === 1 ? ' friend selected' : ' friends selected')"
    [showFooter]="selectedUsers().length > 0"
    [disableButton]="selectedUsers().length === 0"
    (footerEvent)="nextStep()"
/>
