<div class="flex flex-col bg-white rounded-2xl" [ngClass]="{'ring ring-secondary/80': isSelected}">

    <!-- HEADER SUPERIOR -->
    <div class="flex justify-between mx-5 h-16 border-b items-center">
        
        <p class="font-semibold text-darkgray">
            Order Id: 
            <span class="font-main text-secondary">
                {{reservation.id}}
            </span>
        </p>

        <button [ngClass]="{'button-secondary': isSelected}" class="button w-3/12 py-1" (click)="select()">
            {{ isSelected ? '&#10004;&#xFE0E; Selected' : 'Select' }}
        </button>
    </div>
    
    <!-- CONTENIDO INFERIOR -->
    <div class="flex flex-col my-2">

        <div class="flex flex-col px-6" *ngFor="let item of reservation.pending_items">
            <!-- NOMBRE DEL EVENTO Y CANTIDAD DE TICKETS -->
            <div class="flex justify-between">
                
                <p class="w-5/6 font-medium text-darkgray">
                    {{item.name}}
                </p>
                
                <!-- CANTIDAD DE TICKETS -->
                <p class="w-1/6 text-end">
                    {{ reservation.pending_items.length }}
                    {{ reservation.pending_items.length === 1 ? 'Ticket' : 'Tickets' }}
                </p>
                
            </div>

            <!-- DETALLES, PRECIO Y BUYERTYPE -->
            <div class="flex justify-between" *ngFor="let seat of item.seats">
                <span class="block text-darkgray/50 text-xs" >
                    Section: {{seat.tdc_section}} - Row: {{seat.tdc_seat_row}} - Seat: {{seat.tdc_seat_number}}
                </span>
                <span  class="block text-darkgray/50 text-xs" >
                    {{seat.buyer_type}} - {{seat.price | currency:'GBP'}}
                </span>
            </div>

        </div>

    </div>
</div>