<div class="w-full rounded-3xl bg-main/5 p-5">

    <!-- USER CARD -->
    <div class="flex flex-col lg:flex-row items-center lg:justify-between">
        
        <!-- USER DATA -->
        <div class="flex flex-col text-center lg:text-start mb-3 lg:mb-0">
            
            <!-- NAME -->
            <p class=" font-semibold text-xl md:text-lg">
                 <span class="text-darkgray lg:text-secondary">
                    {{name}}
                 </span>
            </p>

            <!-- SUPPORTER ID OR EMAIL -->
            <ng-container *ngIf="supporterId else emailSlot">
                <span class="block font-semibold text-darkgray font-main">
                    Supporter number: {{supporterId.toString()}}
                </span>
            </ng-container>

            <ng-template #emailSlot>
                <span class="block font-semibold text-darkgray font-main">
                    {{email}}
                </span>
            </ng-template>

            <!-- EXTRA DATA (TDC INFO) -->
            <ng-container *ngIf="extraData">
                 
                 <!-- MEMBERSHIPS -->
                 <ng-template *ngIf="extraData.memberships.length">
                     <span *ngFor="let membership of extraData.memberships">
                         {{membership.description}}
                     </span>
                 </ng-template>
     
                 <!-- LOYALTY POINTS -->
                 <span class="block font-main font-medium text-darkgray/50">
                     Loyalty Points Balance: {{getTotalPoints(extraData.loyalty_programs).toString()}}
                 </span>
     
            </ng-container>

        </div>
        
        <!-- ACTIONS (BUTTON OR CUSTOM TEXT + EXPAND BUTTON) -->
        <div [ngClass]="{'lg:justify-around': canExpand, 'lg:justify-center': !showButton && !canExpand, 'lg:justify-end': showButton}" class="flex justify-center w-full lg:w-5/12 xl:w-4/12">
            
            <!-- SELECT BUTTON || CUSTOM TEXT -->
            <ng-container *ngIf="showButton else customTextSlot">
                <button  [ngClass]="{'button-secondary dark:bg-secondary' : isSelected}" class="button w-2/5 md:w-1/4 lg:w-3/5" (click)="onSelect()">
                    {{isSelected ? 'Selected &#10004;&#xFE0E;' : 'Select'}}
                </button>
            </ng-container>
            
            <ng-template #customTextSlot>
                <p class="text-darkgray text-center lg:text-start">
                    {{customText}}
                </p>
            </ng-template>
    
            <!-- EXPAND BUTTON -->
            <ng-container *ngIf="canExpand">
                <button (click)="toggleExpand()" [ngClass]="{'rotate-180': isExpanded}" class="transition-transform duration-200 ease-in cursor-pointer">
                    <i>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-secondary dark:text-white">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>
                    </i>
                </button>
            </ng-container>
            
        </div>
        
    </div>

    <!-- DYNAMIC CONTENT -->
    <ng-container *ngIf="canExpand && isExpanded">
        <ng-content></ng-content>
    </ng-container>

</div>