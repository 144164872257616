import { Component, Signal, computed, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AssociationService } from 'src/app/core/services/association.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { CheckoutService } from 'src/app/core/services/checkout.service';
import { ModalService } from 'src/app/core/services/modal.service';
import { ReservationsService } from 'src/app/core/services/reservations.service';
import { Association, AssociationCollection } from 'src/app/shared/models/association.model';
import { ReservationItem, initCheckout } from 'src/app/shared/models/checkout.model';
import { ReservationCollection } from 'src/app/shared/models/reservations.model';
import { ReservationTransaction } from 'src/app/shared/models/transactions/reservation-transaction.model';
import { User } from 'src/app/shared/models/user.model';

@Component({
  selector: 'app-select-tickets-reserved',
  templateUrl: './select-tickets-reserved.component.html',
  styleUrl: './select-tickets-reserved.component.css'
})
export class SelectTicketsReservedComponent {

  constructor(
    private router:         Router,
    private checkoutService: CheckoutService<ReservationTransaction>
  ){}
  
  // SERVICIOS
  private auth:          AuthService         = inject(AuthService)
  private associations:  AssociationService  = inject(AssociationService);
  private reservations$: ReservationsService = inject(ReservationsService);
  private modalService:  ModalService        = inject(ModalService);
  private user$:         User                = this.auth.userData();
  
  // ASSOCIATIONS
  private selectedUsers:  Signal<AssociationCollection|null> = this.associations.selected;
  
  protected currentUser:  Signal<Association | undefined>    = computed(()=> {
    if(this.user$ && this.selectedUsers()){
      return this.selectedUsers()?.[this.user$.tdc_info.id] as Association;
    }else{
      return undefined;
    }
  })

  protected friends:      Signal<Association[]>              = computed(()=>{
    if(this.selectedUsers()){
      
      const key:  number                = this.user$.tdc_info.id;
      const data: AssociationCollection = Object.assign({}, this.selectedUsers());
      
      delete data![key];
      return Object.values(data!) as Association[];

    }else{
      return [];
    }
  });

  // RESERVATIONS
  protected  reservations:         Signal<ReservationCollection | undefined>  = this.reservations$.reservations;
  public     selectedReservations: {[key: number]: number[]}                  = {};
  public     maxReservations:      number                                     = 10;
  
  // GETTERS
  public get totalReservations():  number {
    return Object.values(this.selectedReservations).reduce((acc, val) => acc + val.length, 0);
  }

  // METHODS

  /**
   * Método que verifica si el usuario tiene asociaciones. 
   * Si no hay reservations, retorna false.
   * 
   * @param {string | number} userId 
   * @returns {boolean} boolean
   */
  public hasAssociations(userId: string | number): boolean {
    
    if(!this.reservations()){
      return false;
    } 
    
    return this.reservations()![userId] && this.reservations()![userId].length > 0;
  }

  /**
   * Método que recibe el id de la reserva y el id del usuario para actualizar las reservas seleccionadas.
   * Este método comprueba si se han superado las reservas máximas, si es asi solo dejará seleccionar las reservas ya seleccionadas.
   * Si en el objeto de selectedReservations ya existe el id del usuario, se comprueba si el id de la reserva existe ya en el array. 
   * En caso de existir, se elimina y si no se añade. Finalmente, en caso de no existir el id del usuario, 
   * se crea un nuevo array con el id de la reserva.
   * @param {number} reservationId 
   * @param {number} userId 
   */
  public updateReservations(reservationId: number, userId: number): void {
    
    // Comprueba si se han superado las reservas máximas
    if(this.totalReservations >= this.maxReservations){
      
      if(this.selectedReservations[userId].includes(reservationId)){
        this.selectedReservations[userId] = this.selectedReservations[userId].filter((id: number) => id !== reservationId);
        return;
      }else{
        return;
      }

    }

    // Actualiza o crea la reserva seleccionada
    if(this.selectedReservations[userId]){
      this.selectedReservations[userId] = this.selectedReservations[userId].includes(reservationId) ?
        this.selectedReservations[userId].filter((id: number) => id !== reservationId) :
        [...this.selectedReservations[userId], reservationId];
    }else{
      this.selectedReservations[userId] = [reservationId];
    }

  }

  /**
   * Método que inicia el proceso de checkout.
   * Este método crea un modal de confirmación con un texto y un título determinados.
   * Si el usuario confirma, se llama al método nextStep, si no, no se hace nada.
   * @see nextStep
   */
  public startCheckout(): void {

    const modalTexts: {title: string, text: string} = {
      title: 'Continue?',
      text: 'By clicking continue, your seats will be placed on hold and you will be redirected to the checkout page.'
    }

    this.modalService.createConfirmationModal(modalTexts.title, modalTexts.text).subscribe((response) => {
      response.isConfirmed ? this.nextStep() : null;
    });

  }

  /**
   * Método que inicia el proceso de checkout.
   * Este método instancia un objeto Data en el que se crea la llave friends_family_orders con el objeto selectedReservations.
   * Se filtra que en dicho objeto no haya arrays vacios, si los hay se eliminan. Finalmente con el objeto formateado, 
   * se llama al método initCheckout del servicio de checkout y se envia al usuario a la vista de Checkout.
   * 
   * @see startCheckout
   */
  private nextStep(): void {
    
    const data: ReservationItem = {
      friends_family_orders: this.selectedReservations,
    }

    // Delete empty arrays from data object
    Object.keys(data.friends_family_orders).forEach(key => {
      if (data.friends_family_orders[key as any].length === 0) {
      delete data.friends_family_orders[key as any];
      }
    });
   
    this.checkoutService.initCheckout(data, 'reservation').subscribe({     
      next:  (response: initCheckout) => {
        this.auth.getUser(true).then(
          () => this.router.navigate(['renew-tickets/checkout'],{ queryParams: {type:'reservation' , transaction: response.id } })
        )
      },
      error: (error:any)    => this.modalService.createErrorModal(error.error.message)
    });
    
  }

}
